<template>
  <div class="height-100">
    <a
      :href="getDownloadLink.download_link"
      class="btn btn-success btn-sm"
      target="_blank"
      v-if="getDownloadLink && showDownload"
    >
      Download Design
    </a>
    <iframe :src="previewUrl" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {
          id: 0,
        };
      },
    },
    showDownload: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getPageDesigns", "getDefaults"]),
    getDownloadLink() {
      const page_design =
        this.item.design_default == "0"
          ? this.item.page_design
          : this.getDefaults.default_page;
      const [design] = this.getPageDesigns.filter((d) => d.id === page_design);
      return design;
    },
    previewUrl() {
      return `/wp-admin/admin-ajax.php?action=vue_preview&preview=1&id=${this.item.ID}&design=${this.design}`;
    },
    design() {
      return this.$root.designs;
    },
  },
  mounted() {},
};
</script>
<style scoped>
div {
  position: relative;
}
iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
}
.btn {
  position: absolute;
  top: 0;
}
</style>