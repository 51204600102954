<template>
  <div class="container height-100">
    <div class="row mb-2">
      <div class="col-md-12">
        <div class="list-group">
          <div class="list-group-item">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :value="1"
                id="useDefault"
                :checked="getDefault"
                @change="setDefault"
              />
              <label class="form-check-label" for="useDefault">
                Use Default Design
              </label>
              <i
                class="fa fa-cog ml-2"
                v-if="getDefault"
                @click="show_default_element = !show_default_element"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <default-design-modal
      @click="changeDefaultDesign"
      v-if="show_default_element"
    />
    <div class="row" v-if="!show_default_element">
      <div
        v-for="design in getSpecificDesigns"
        :key="design.id"
        class="col-lg-4 col-md-4 col-sm-4 col-xs-6 mb-3 card-parent"
        :class="{ 'use-default': getDefault }"
      >
        <div
          class="card"
          :class="{
            active: isDesignActive(design.id),
          }"
          :style="backgroundImage(design)"
          @click="setPageDesign(design)"
        >
          <div class="card-body d-flex">
            <span v-if="design.image_src === ``" class="no-design">
              No Design
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultDesignModal from "./DefaultDesignModal.vue";
import PicturesListModal from "./PicturesListModal.vue";

export default {
  components: { DefaultDesignModal },
  data() {
    return {
      selected_design: "",
      show_default_element: false,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["getPageDesigns", "getDefaults"]),
    selectedDesign() {
      if (this.getDefault) {
        return this.getDefaults.default_page;
      } else {
        return this.item.page_design !== "" ? this.item.page_design : "_";
      }
    },
    getDefault() {
      return (
        parseInt(this.item?.design_default) === 1 ||
        this.item?.design_default === ""
      );
    },
    getSpecificDesigns() {
      switch (this.item.page_type) {
        case "writings_group":
          return this.getPageDesigns.filter((d) =>
            d.groups.find((g) => g === "writing")
          );
        case "toc_page":
          return this.getPageDesigns.filter((d) =>
            d.groups.find((g) => g === "writing")
          );
        case "writing":
          return this.getPageDesigns.filter((d) =>
            d.groups.find((g) => g === "writing")
          );
        case "chapter":
          return this.getPageDesigns.filter((d) =>
            d.groups.find((g) => g === "chapter")
          );
        case "picture":
          return this.getPageDesigns.filter((d) =>
            d.groups.find((g) => g === "picture")
          );
        case "content":
          return this.getPageDesigns.filter((d) =>
            d.groups.find((g) => g === "picture")
          );
        case "dedication_page":
          return this.getPageDesigns.filter((d) =>
            d.groups.find((g) => g === "writing")
          );
        case "intro_page":
          return this.getPageDesigns.filter((d) =>
            d.groups.find((g) => g === "writing")
          );
        default:
          return this.getPageDesigns.filter((d) =>
            d.groups.find((g) => g === "writing")
          );
      }
    },
    hasSecondPicture() {
      return this.item.design?.pictures > 1;
    },
    getTemplateFolder() {
      return this.$root.template_name;
    },
  },
  methods: {
    backgroundImage(design) {
      if (design.image_src) {
        return `background-image:url(/wp-content/themes/${this.getTemplateFolder}${design.image_src})`;
      } else {
        return ``;
      }
    },
    isDesignActive(id) {
      return this.selectedDesign === id;
    },
    hasPictures(design) {
      if (this.selected_design !== design.id) return false;
      return this.item.design?.pictures > 0;
    },
    changeDefaultDesign() {
      // this.$emit("reload-preview");
      this.show_default_element = false;
    },
    showDesignButton(design) {
      if (this.getDefault) {
        return false;
      } else {
        if (this.item.page_design === design.id) return false;
      }
      return true;
    },
    setPageDesign(design) {
      if (this.getDefault) return;
      if (this.selected_design === design.id) return;
      this.selected_design = design.id;
      this.item.page_design = design.id;
      this.item.design = design;
      this.$store.dispatch("updatePageMeta", [
        this.item.ID,
        "page_design",
        `${design.id}`,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
          });
          // this.$emit("reload-preview");
          this.$emit("set-design");
        },
      ]);
    },
    setDefault(evt) {
      const checked = evt.target.checked ? "1" : "0";
      this.item.design_default = checked;
      this.$store.dispatch("updatePageMeta", [
        this.item.ID,
        "design_default",
        checked,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
          });
          // this.$emit("reload-preview");
          // this.$emit("set-design");
        },
      ]);
    },
    setImage1() {
      this.$modal.show(
        PicturesListModal,
        { item: this.item, meta_key: "image1_id" },
        {
          name: "set-image-modal",
          width: 500,
          height: 400,
        },
        {
          closed: () => {
            // this.$emit("reload-preview");
          },
        }
      );
    },
    setImage2() {
      this.$modal.show(
        PicturesListModal,
        { item: this.item, meta_key: "image2_id" },
        {
          name: "set-image-modal",
          width: 500,
          height: 400,
        },
        {
          closed: () => {
            // this.$emit("reload-preview");
          },
        }
      );
    },
  },
  mounted() {
    this.selected_design =
      this.item.page_design !== "" ? this.item.page_design : "_";
  },
};
</script>
<style scoped>
.container {
  overflow-y: scroll;
}
.card {
  min-height: 220px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
}
.card-parent > .card:hover {
  border: 5px solid green;
}
.card.active {
  border: 5px solid green;
  opacity: 0.5;
}
.use-default > .card {
  opacity: 0.5;
}

.use-default > .card:hover {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.use-default > .card.active:hover {
  border: 5px solid green;
}
.fa.fa-cog:hover {
  color: green;
  text-shadow: 1px 1px 1px #a7a7a7;
  cursor: pointer;
}
.no-design {
  text-transform: uppercase;
  display: block;
  margin: auto auto;
  font-weight: 600;
}
</style>