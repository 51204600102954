<template>
  <div class="height-100">
    <button
      class="btn btn-success btn-sm btn-save"
      v-if="show_save"
      @click="saveChanges"
    >
      <i class="fa fa-save"></i> Save
    </button>
    <div class="card height-100">
      <Spinner v-if="showSpinner" />

      <input
        type="text"
        class="form-control mb-2"
        :value="postTitle"
        @input="triggerUpdate"
        v-if="!showSpinner"
        :disabled="!titleInput"
      />

      <CKCustomEditorPrompt
        :item="item"
        @triggerUpdate="triggerUpdate"
        v-if="!showSpinner"
        ref="editor"
        :prompt="item"
      />
    </div>
  </div>
</template>

<script>
import Spinner from "./Spinner";
import CKCustomEditorPrompt from "./CKCustomEditorPrompt";
import { mapGetters } from "vuex";

export default {
  components: {
    Spinner,
    CKCustomEditorPrompt,
  },
  data() {
    return {
      timer: {
        type: Object,
        default: 0,
      },
      show_save: false,
      button_delay: false,
    };
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    titleInput: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getFonts"]),
    showSpinner() {
      return !Object.prototype.hasOwnProperty.call(this.item, "ID");
    },
    items: {
      get() {
        return this.$store.getters.getLegacyJournal;
      },
      set(value) {
        this.$store.commit("SET_LEGACY_JOURNAL", value);
      },
    },
    postTitle: {
      get() {
        return this.item.post_title;
      },
      set(value) {
        this.item.post_title = value;
      },
    },
    title: {
      get() {
        return this.item.formatted_title;
      },
      set(value) {
        this.item.post_title = value;
        this.item.formatted_title = value;
      },
    },
    content: {
      get() {
        return this.item.post_content;
      },
      set(value) {
        this.item.post_content = value;
      },
    },
    stringFonts() {
      let fonts = "";
      this.getFonts.map((f) => {
        fonts += `${f.name}=${f.id};`;
      });
      return fonts;
    },
    getPostTitle() {
      const pattern = /<h1>(.*)<\/h1>/;
      const results = pattern.exec(this.$refs.editor.editorData);
      return results[1];
    },
    getPostContent() {
      const n = this.$refs.editor.editorData.indexOf("</h1>");
      return this.$refs.editor.editorData.slice(n);
    },
  },
  methods: {
    saveChanges() {
      clearTimeout(this.timer);

      this.$store.dispatch("updatePage", [
        this.item.ID,
        this.item.post_title,
        this.item.post_content,
        ({ message, stripped_title }) => {
          this.item.stripped_title = stripped_title;
          this.$toast.open({
            message,
            position: "bottom-left",
            type: "success",
          });
        },
      ]);
      this.show_save = false;
    },
    triggerUpdate() {
      clearTimeout(this.timer);
      this.show_save = true;
      this.timer = setTimeout(() => {
        this.saveChanges();
      }, 5000);
    },
  },
  mounted() {
    // console.log(this.item);
  },
};
</script>
<style>
.tox-tinymce-inline {
  z-index: 999;
}
</style>
<style scoped>
.card {
  border: none;
}
/* 
.card-body {
  height: 500px;
  overflow-y: scroll;
}
.card-header {
  max-height: 60px;
  overflow-y: scroll;
}
*/
.btn-save {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 5px;
}
.card-header > p,
.card-body > p {
  margin-bottom: 0;
}
</style>