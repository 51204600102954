<template>
  <ul class="nav nav-tabs">
    <li
      v-if="showItems"
      role="presentation"
      class="nav-link"
      :class="{ active: isActive === 'items' }"
      @click="clickItems"
    >
      <a href="javascript:void(0);">Items</a>
    </li>
    <li
      v-if="showContent"
      role="presentation"
      class="nav-link"
      :class="{ active: isActive === 'content' }"
      @click="clickContent"
    >
      <a href="javascript:void(0);">Content</a>
    </li>

    <li
      v-if="showDesigns"
      role="presentation"
      class="nav-link"
      :class="{ active: isActive === 'design' }"
      @click="clickDesign"
    >
      <a href="javascript:void(0);">Design</a>
    </li>
    <li
      v-if="showSettings"
      role="presentation"
      class="nav-link"
      :class="{ active: isActive === 'settings' }"
      @click="clickSettings"
    >
      <a href="javascript:void(0);">Settings</a>
    </li>
    <li
      v-if="hasPagesTab && hasSubpages && showDesigns"
      role="presentation"
      class="nav-link"
      :class="{ active: isActive === 'subpages' }"
      @click="clickSubpages"
    >
      <a href="javascript:void(0);">Pages</a>
    </li>
    <li
      v-if="showPreview"
      role="presentation"
      class="nav-link"
      :class="{ active: isActive === 'preview' }"
      @click="clickPreview"
    >
      <a href="javascript:void(0);">Preview</a>
    </li>
    <li
      v-if="showMyUploads"
      role="presentation"
      class="nav-link"
      :class="{ active: isActive === 'my-uploads' }"
      @click="clickUploads"
    >
      <a href="javascript:void(0);">My Uploads</a>
    </li>
  </ul>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    default: {
      type: String,
      default: "content",
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    item_id: {
      type: Number,
      default: 0,
    },
    showPictures: {
      type: Boolean,
      default: true,
    },
    showItems: {
      type: Boolean,
      default: false,
    },
    showPreview: {
      type: Boolean,
      default: true,
    },
    showContent: {
      type: Boolean,
      default: true,
    },
    showSettings: {
      type: Boolean,
      default: false,
    },
    showMyUploads: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
      hasPagesTab: false,
    };
  },
  computed: {
    ...mapGetters(["getPages", "getGlobalSettings"]),
    isActive() {
      return this.active ? this.active : this.default;
    },
    hasSubpages() {
      return (
        //(this.getItem?.post_content.match(/<!-- pagebreak -->/g) || []).length > 0
        (
          this.getItem?.post_content.match(
            /<div class="page-break" style="page-break-after:always;">/g
          ) || []
        ).length > 0
      );
    },
    showDesigns() {
      return this.getGlobalSettings.designs == "1";
    },
    needsPicture() {
      if (this.getItem?.page_type === "picture") return true;
      if (this.hasSubpages) {
        let hasPic = 0;
        if (typeof this.getItem?.pages_settings === "object") {
          this.getItem?.pages_settings?.map((s) => {
            hasPic = hasPic + (s.design?.pictures ?? 0);
          });
        }
        return hasPic > 0;
      } else {
        if (parseInt(this.getItem?.design_default) === 1) return false;
        return this.getItem?.design?.pictures > 0;
      }
    },
    getItem() {
      if (this.item.ID) return this.item;
      if (this.item.ID !== this.item_id) {
        return this.getPages.find((p) => p.ID === this.item_id);
      }
      return {};
    },
  },
  methods: {
    clickButton() {
      this.$emit("close");
    },
    clickContent() {
      this.active = "content";
      this.$emit("nav-change");
    },
    clickItems() {
      this.active = "items";
      this.$emit("nav-change");
    },
    clickPageType() {
      this.active = "page_type";
      this.$emit("nav-change");
    },
    clickDesign() {
      this.active = "design";
      this.$emit("nav-change");
    },
    clickSubpages() {
      this.active = "subpages";
      this.$emit("nav-change");
    },
    clickPictures() {
      this.active = "pictures";
      this.$emit("nav-change");
    },
    clickPreview() {
      this.active = "preview";
      this.$emit("nav-change");
    },
    clickSettings() {
      this.active = "settings";
      this.$emit("nav-change");
    },
    clickUploads() {
      this.active = "my-uploads";
      this.$emit("nav-change");
    },
  },
  mounted() {},
};
</script>
<style scoped>
.nav-tabs {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  position: absolute;
  left: -56px;
  top: 40px;
  border: none;
  background-color: #ccc;
  border-radius: 0;
}
.nav-tabs > .nav-link {
  padding: 0;
  border: none;
  background-color: #ccc;
}
.nav-tabs > .nav-link.active {
  background-color: #fff;
}
.nav-tabs > li {
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  transform-origin: center center;
  list-style-type: none;
  border-radius: 0;

  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.nav-tabs > li:first-child {
  margin-bottom: -1px;
}
.nav-tabs > li > a {
  display: block;
  padding: 1.25rem 1rem;
  color: #000;
  cursor: pointer;
  border-radius: 0;
}
</style>