<template>
  <div>
    <div class="row mb-2">
      <div class="col-md-12">
        <div class="list-group">
          <div class="list-group-item">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :value="1"
                id="useDefault"
                :checked="getDefault"
                @change="setDefault"
              />
              <label class="form-check-label" for="useDefault">
                Use Default Design
              </label>
              <i
                class="fa fa-cog ml-2"
                v-if="getDefault"
                @click="show_default_element = !show_default_element"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <default-design-modal
      v-if="show_default_element"
      @click="changeDefaultDesign"
    />
    <div class="row" v-if="!show_default_element">
      <div
        v-for="design in getSpecificDesigns"
        :key="design.id"
        class="col-lg-4 col-md-4 col-sm-4 col-xs-6 mb-3"
      >
        <div
          class="card"
          :class="{
            active: getSelectedDesign.id === design.id,
            disabled: getDefault,
          }"
          :style="`background-image:url(/wp-content/themes/${getTemplateFolder}${design.image_src})`"
          @click="setPageDesign($event, design)"
        >
          <div class="card-body d-flex flex-column" v-if="hasPictures(design)">
            <div class="mt-auto">
              <button
                class="btn btn-success btn-sm btn-block"
                @click="setImage1"
              >
                Set Image 1
              </button>
              <button
                class="btn btn-success btn-sm btn-block"
                v-if="hasSecondPicture"
                @click="setImage2"
              >
                Set Image 2
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultDesignModal from "./DefaultDesignModal.vue";
import PicturesListModal from "./PicturesListModal.vue";

export default {
  components: { DefaultDesignModal },
  data() {
    return {
      selected_design: "",
      show_default_element: false,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    selected_page: {
      type: Object,
      default() {
        return {};
      },
    },
    selected_index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(["getPageDesigns"]),
    getSelectedDesign() {
      return this.selected_page.design;
    },
    getDefault: {
      get() {
        return this.selected_page.use_default ?? true;
      },
      set(value) {
        this.selected_page.use_default = value;
      },
    },
    getSpecificDesigns() {
      switch (this.item.page_type) {
        case "writing":
          return this.getPageDesigns.filter((d) =>
            d.groups.find((g) => g === "page")
          );
        case "dedication_page":
          return this.getPageDesigns.filter((d) =>
            d.groups.find((g) => g === "page")
          );
        case "intro_page":
          return this.getPageDesigns.filter((d) =>
            d.groups.find((g) => g === "page")
          );
        case "chapter":
          return this.getPageDesigns.filter((d) =>
            d.groups.find((g) => g === "chapter")
          );
        case "picture":
          return this.getPageDesigns.filter((d) =>
            d.groups.find((g) => g === "picture")
          );
      }
      return {};
    },
    hasSecondPicture() {
      return this.item.design?.pictures > 1;
    },
    getTemplateFolder() {
      return this.$root.template_name;
    },
  },
  methods: {
    hasPictures(design) {
      if (this.getSelectedDesign.id !== design.id) return false;
      return this.getSelectedDesign.pictures > 0;
    },
    changeDefaultDesign() {
      // this.$emit("reload-preview");
      this.show_default_element = false;
    },
    setPageDesign(evt, design) {
      if (this.getDefault) return;
      if (design.id === this.selected_page.design.id) return;
      const close = parseInt(design.pictures) === 0;
      this.selected_design = design.id;
      this.item.page_design = design.id;
      if (this.selected_index === 0) {
        this.item.design = design;
        this.$store.dispatch("updatePageMeta", [
          this.item.ID,
          "page_design",
          design.id,
          ({ message }) => {
            if (close) {
              this.$toast.open({
                message,
                position: "bottom-left",
              });
            }
          },
        ]);
      }
      this.$emit("design-selected", [design, true, close]);
    },
    setDefault(evt) {
      this.show_default_element = false;
      let save = false;
      let close = false;
      if (this.selected_page.use_default !== evt.target.checked) {
        save = true;
      }
      if (evt.target.checked) {
        //close = true;
      }
      if (this.selected_index === 0) {
        const checked = evt.target.checked ? "1" : "0";
        this.item.design_default = checked;
        this.$store.dispatch("updatePageMeta", [
          this.item.ID,
          "design_default",
          checked,
          () => {},
        ]);
      }
      this.selected_page.use_default = evt.target.checked;
      this.$emit("design-selected", [{}, save, close]);
    },
    setImage1() {
      this.$modal.show(
        PicturesListModal,
        {
          item: this.item,
          meta_key: "image1_id",
          selected_page: this.selected_page,
          selected_index: this.selected_index,
        },
        {
          name: "set-image-modal",
          width: 500,
          height: 400,
        },
        {
          closed: () => {
            // this.$emit("reload-preview");
          },
        }
      );
    },
    setImage2() {
      this.$modal.show(
        PicturesListModal,
        {
          item: this.item,
          meta_key: "image2_id",
          selected_page: this.selected_page,
          selected_index: this.selected_index,
        },
        {
          name: "set-image-modal",
          width: 500,
          height: 400,
        },
        {
          closed: () => {
            // this.$emit("reload-preview");
          },
        }
      );
    },
  },
};
</script>
<style scoped>
.card {
  min-height: 220px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
}
.card:hover {
  border: 1px solid green;
}
.card.active {
  border: 1px solid green;
}
.card.disabled {
  opacity: 0.5;
}
.card.disabled:hover {
  border: 1px solid rgba(0, 0, 0, 0.125);
  cursor: auto;
}
.card.active.disabled {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.fa.fa-cog:hover {
  color: green;
  text-shadow: 1px 1px 1px #a7a7a7;
  cursor: pointer;
}
</style>