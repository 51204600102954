<template>
  <div class="row">
    <div class="text-center col-md-12">Choose Default</div>
    <div
      v-for="design in getDefaultPages"
      :key="design.id"
      class="col-lg-4 col-md-4 col-sm-4 col-xs-6 mb-3"
    >
      <div
        class="card"
        :class="{ active: isDesignActive(design.id) }"
        :style="`background-image:url(/wp-content/themes/${this.getTemplateFolder}${design.image_src})`"
        @click="setDefaultDesign($event, design)"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      selected_design: "",
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["getPageDesigns", "getDefaults"]),
    getDefault() {
      return (
        parseInt(this.item?.design_default) === 1 ||
        this.item?.design_default === ""
      );
    },
    getDefaultPages() {
      return this.getPageDesigns.filter((d) =>
        d.groups.find((g) => g === "default")
      );
    },
    getDefaultDesign() {
      return this.getPageDesigns.find(
        (d) => d.id === this.getDefaults.default_page
      );
    },
    getTemplateFolder() {
      return this.$root.template_name;
    },
  },
  methods: {
    isDesignActive(id) {
      return this.selected_design === id;
    },
    setDefaultDesign(evt, design) {
      this.$store.dispatch("updateUserMeta", [
        "legacy_journal_default_page",
        design.id,
        ({ meta_value, message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
          });
          this.$store.commit("UPDATE_DEFAULTS", ["default_page", meta_value]);
          this.selected_design = meta_value;
          this.$emit("click");
        },
      ]);
    },
  },
  mounted() {
    this.selected_design = this.getDefaults.default_page;
  },
};
</script>
<style scoped>
.card {
  min-height: 220px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
}
.card:hover {
  border: 5px solid green;
}
.card.active {
  border: 5px solid green;
}
</style>