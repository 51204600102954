<template>
  <div class="container">
    <div class="row" v-if="selected_page === ``">
      <div
        v-for="(subpage, index) in getSubpages"
        :key="index"
        class="col-lg-4 col-md-4 col-sm-4 col-xs-6 mb-3"
      >
        <div
          class="card"
          @click="triggerClick(subpage, index)"
          :style="getBackground(subpage)"
        >
          <div class="card-body d-flex flex-column">
            <div class="mt-auto text-center">
              <strong>{{ subpage.name }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SubpagesDesignModal
      v-if="selected_page !== ``"
      :selected_page="selected_page"
      :selected_index="selected_index"
      :item="item"
      @design-selected="designSelected"
      @reload-preview="$emit('reload-preview')"
    />
  </div>
</template>

<script>
import SubpagesDesignModal from "./SubpagesDesignModal.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SubpagesDesignModal,
  },
  data() {
    return {
      selected_page: "",
      selected_design: "",
      selected_index: 0,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["getPageDesigns", "getDefaults"]),
    getSubpages() {
      return this.item.pages_settings;
    },
    getDefaultDesign() {
      return this.getPageDesigns.find(
        (d) => d.id === this.getDefaults.default_page
      );
    },
    getTemplateFolder() {
      return this.$root.template_name;
    },
  },
  methods: {
    triggerClick(page, index) {
      this.selected_page = page;
      this.selected_index = index;
    },
    getBackground(subpage) {
      let bg_src = this.getDefaultDesign?.image_src;
      if (!subpage.use_default) {
        bg_src = subpage.design.image_src;
      }
      return `background-image:url(/wp-content/themes/${this.getTemplateFolder}${bg_src})`;
    },
    emptySelectedPage() {
      this.selected_page = ``;
    },
    designSelected([design, set, close]) {
      if (set) {
        this.selected_page.design = design;
        this.$store.dispatch("updatePageMeta", [
          this.item.ID,
          "pages_settings",
          JSON.stringify(this.item.pages_settings),
          ({ message }) => {
            this.$toast.open({
              message,
              position: "bottom-left",
            });
            // this.$emit("reload-preview");
          },
        ]);
      }
      if (close) {
        this.emptySelectedPage();
      }
    },
    initPageSettings() {
      const pages = (
        this.item.post_content.match(
          /<div class="page-break" style="page-break-after:always;">/g
        ) || []
      ).length;
      const subpages = [];
      for (let i = 0; i <= pages; i++) {
        let obj = this.item.pages_settings[i];
        if (!obj) {
          obj = Object.assign(
            {
              name: "Page " + (i + 1),
              design: this.getPageDesigns.find((d) => d.id == `_`),
              use_default: true,
            },
            i
          );
        }
        subpages.push(obj);
      }
      this.item.pages_settings = subpages;
    },
  },
  mounted() {
    this.initPageSettings();
  },
};
</script>
<style scoped>
.card {
  min-height: 220px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
}
.card:hover {
  border: 1px solid green;
}
.card.active {
  border: 1px solid green;
}
.container {
  height: 560px;
  overflow-y: scroll;
}
</style>